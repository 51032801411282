<template>
    <div class="estados-pedidos container-fluid">
        <div class="row my-3 mt-4">
            <div class="col-lg-12">
                <titulo-divisor titulo="Estados de pedidos internos" class="icon-option">
                    <i class="icon-plus-circle  f-30 cr-pointer" @click="openCreateState"/>
                </titulo-divisor>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <draggable tag="div" :list="estadoPedidosInternos" class="row mx-0 py-3" handle=".handle" @change="changePosition()">
                    <div v-for="data in estadoPedidosInternos" :key="data.id" class="col-12 border-nth br-4 py-1 text-muted2 pl-2">
                        <div class="row justify-content-lg-between">
                            <div class="col-lg-3 my-auto">
                                <div class="d-middle ml-3">
                                    <i class="icon-menu handle cr-pointer mr-2" />
                                    <i class="icon-circle-fill f-20" :style="`color:${data.color}`" />
                                    <p class="f-12 my-auto w-100">{{ data.estado }}</p>
                                </div>
                            </div>
                            <div class="col-lg-9">
                                <div class="d-flex justify-content-lg-end icon-option">
                                    <el-tooltip content="Editar estado" effect="light" visible-arrow>
                                        <i class="icon-pencil f-22 mr-1 cr-pointer" @click="openEditState(data)" />
                                    </el-tooltip>
                                    <el-tooltip content="Eliminar" effect="light" visible-arrow>
                                        <i class="icon-trash-can-outline f-22 cr-pointer" @click="openModalDelete(data.id)" />
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        <modal ref="modalDeleteState" titulo="Eliminar estado del pedido" :btn-eliminar="true" @eliminar="deleteState">
            <div class="container">
                <div class="row my-4">
                    <div class="col-lg-12 text-center">
                        <p class="f-12">¿Está seguro que quiere eliminar este estado?</p>
                    </div>
                </div>
            </div>
        </modal>
        <modal-create-state ref="openModalCreateState" />
        <modal-edit-state ref="openModalEditeState" />
    </div>
</template>

<script>
import { Chrome } from 'vue-color'
import { mapGetters, mapActions } from 'vuex';
import modalCreateState from './partials/modalCreateState.vue'
import modalEditState from './partials/modalEditState.vue'
export default {
    components:{
        modalCreateState,
        modalEditState,
        'chrome-picker': Chrome
    },
    data(){
        return {
            createStateVisible: false,
            createStateVisible2: false,
            nombre: '',
            model: '',
            colorEstado: '#000000',
            idToDelete: Number,
            dataEstados: []
        }
    },
    created(){
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'configurar.almacen',
            'configurar.almacen.estados.pedidos.internos',
        ]);
        this.Action_get_estados_pedidos_internos()
    },
    computed: {
        ...mapGetters({
            estadoPedidosInternos: 'configurar/almacen/pedidos_internos/estadoPedidosInternos',
        }),
    },
    methods: {
        ...mapActions({
            Action_get_estados_pedidos_internos: 'configurar/almacen/pedidos_internos/Action_get_estados_pedidos_internos',
            Action_eliminar_estados_pedidos_internos: 'configurar/almacen/pedidos_internos/Action_eliminar_estados_pedidos_internos',
			Action_cambiar_posicion_pedidos_internos: 'configurar/almacen/pedidos_internos/Action_cambiar_posicion_pedidos_internos'
        }),
        openCreateState(){
            this.$refs.openModalCreateState.toggle()
        },
        openEditState(data){
            this.$refs.openModalEditeState.toggle(data)
        },
        openModalDelete(id){
            this.idToDelete = id
            this.$refs.modalDeleteState.toggle()
        },
        async createState(){
            const valid = await this.$refs.validator.validate()
            if(!valid) return
        },
        async deleteState(){
            await this.Action_eliminar_estados_pedidos_internos(this.idToDelete)
            this.$refs.modalDeleteState.toggle()
        },
		async changePosition(){
			await this.Action_cambiar_posicion_pedidos_internos(this.estadoPedidosInternos.map((e, idx) => ({...e, posicion: idx+1})))
		}
    }
}
</script>


<style lang="scss" scoped>
.border-nth{
    border-top: 1px solid #dee2e6 !important;
    &:nth-last-child(1){
        border-bottom: 1px solid #dee2e6 !important;
    }
}
</style>
