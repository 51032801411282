<template>
    <modal ref="modal" titulo="Crear estado de pedido">
        <div class="container">
            <ValidationObserver ref="validator">
                <div class="row my-3 justify-content-center">
                    <div class="col-lg-12">
                        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                            <p class="input-label-top">Nombre</p>
                            <el-input v-model="nombre" placeholder="Nombre" size="small" />
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <ValidationProvider name="color" rules="required" v-slot="{ errors }">
                            <p class="input-label-top pl-0">Color</p>
                            <chrome-picker v-model="colorEstado" ref="colorPicker" class="w-100" />
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <template slot="end-buttons">
            <button class="btn btn-general f-12 mr-2" @click="saveState">Guardar</button>
        </template>
    </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Chrome } from 'vue-color'
export default {
    components:{
        'chrome-picker': Chrome
    },
    data() {
        return {
            nombre: '',
            colorEstado: '#000000',
        }
    },
    computed: {
        ...mapGetters({
            estadoPedidosInternos: 'configurar/almacen/pedidos_internos/estadoPedidosInternos',
        }),
    },
    methods:{
        ...mapActions({
            Action_post_crear_estado: 'configurar/almacen/pedidos_internos/Action_post_crear_estado',
        }),
        toggle(){
            this.$refs.validator.reset()
            this.$refs.modal.toggle()
        },
        async saveState(){
            const valid = await this.$refs.validator.validate()
            if (!valid) return
            const payload = {
                estado:this.nombre,
                color:this.colorEstado.hex,
				posicion: this.estadoPedidosInternos.length+1
            }
            await this.Action_post_crear_estado(payload)
            this.$refs.modal.toggle()
            this.clean()
        },
        clean(){
            this.nombre = ''
            this.color = ''
        }
    }
}
</script>

<style>

</style>