<template>
    <modal ref="modal" titulo="Editar estado de pedido">
        <div class="container">
            <ValidationObserver ref="validatorEdit">
                <div class="row my-3 justify-content-center">
                    <div class="col-lg-12">
                        <ValidationProvider name="nombre" rules="required" v-slot="{ errors }">
                            <p class="input-label-top">Nombre</p>
                            <el-input v-model="nombre" placeholder="Nombre" size="small" />
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <ValidationProvider name="color" rules="required" v-slot="{ errors }">
                            <p class="input-label-top pl-0">Color</p>
                            <chrome-picker v-model="colorEstado" ref="colorPicker" class="w-100" />
                            <vee-error :text="errors[0]"/>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <template slot="end-buttons">
            <button class="btn btn-general f-12 mr-2" @click="editState">Guardar</button>
        </template>
    </modal>
</template>

<script>
import { Chrome } from 'vue-color'
import { mapActions } from 'vuex';
export default {
    components:{
        'chrome-picker': Chrome
    },
    data() {
        return {
            nombre: '',
            colorEstado:'#000000',

            estado: {}
        }
    },
    watch: {
        colorEstado() {
            this.colorEstado = this.$refs.colorPicker.val.hex
        }
    },
    methods:{
        ...mapActions({
            Action_update_estados_pedidos_internos: 'configurar/almacen/pedidos_internos/Action_update_estados_pedidos_internos',  
        }),
        toggle(item){
            this.estado = item
            this.nombre = item.estado
            this.colorEstado = item.color
            this.$refs.colorPicker.val.hex = item.color
            this.$refs.modal.toggle()
        },
        async editState(){
            const payload = {
                estado:this.nombre,
                color:this.colorEstado
            }
            const params = {
                id:this.estado.id,
                payload:payload
            }
            await this.Action_update_estados_pedidos_internos(params)
            this.$refs.modal.toggle()
        }
    }
}
</script>

<style>

</style>